:root {
  --bg-color: #f5f5f5;
  --bg-color2: #eff3ff;
  --accent: #ffefcc;
  --bg-color3: #d2f0e9;
  --yellow: #ffb800;

  --white: #fff;
  --darkaccent2: #a50f0e;
  --black: #282828;
  --black1: #1c1c1c;
  --black2: #292929;

  --bs-gutter-x: 3rem;
  --accent2: #ea2316;
  --accent3: #ceeaed;
}
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--black);
}

body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.4;
  background: var(--bg-color);
}
.h1,
h2 {
  font-size: 3rem;
}
.h3 {
  font-size: 2rem;
}
.h4 {
  font-size: 1.75rem;
}
.h5 {
  font-size: 1.75rem;
}
.h6 {
  font-size: 1.25rem;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }
}
.mainWrapper {
  header {
    padding: 1rem 0;
    display: none;

    @media (min-width: 992px) {
      padding: 1rem 0;
      display: none;
    }

    position: relative;
    z-index: 2;

    .logo-wrapper {
      a {
        img {
          max-width: 100%;
        }
      }
    }
  }
  .hero-section {
    padding: 2rem 0 0 0;
    position: relative;
    overflow: hidden;
    text-align: center;

    .container {
      position: relative;
      z-index: 2;

      @media (min-width: 992px) {
        max-width: 100%;
      }

      .row {
        min-height: 50vh;
        align-items: center;
        justify-content: center;

        @media (min-width: 992px) {
          align-content: flex-end;
        }

        .hero-container {
          padding: 64px 24px;
          border-radius: 24px;
          width: 100%;
          flex: 0 0 auto;
          background: var(--black1);

          @media (min-width: 992px) {
            width: 50%;
            text-align: left;
            padding: 3rem 3rem;
          }

          .headline-wrapper {
            h1 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 10px;
              color: var(--white);
              letter-spacing: -0.3px;
              text-transform: capitalize;
              padding: 8px 6px;
              border-radius: 30px;

              @media (min-width: 992px) {
                padding: 8px 0px;
                text-align: center;
                width: 70%;
              }
            }
          }

          .sub-hero-section {
            .hero-section-wrapper {
              .hero-section-wrapper-content {
                h2 {
                  font-size: 30px;
                  line-height: 1.33;
                  margin-bottom: 1rem;
                  color: var(--white);

                  @media (min-width: 992px) {
                    font-size: 3rem;
                    margin-bottom: 1.5rem;
                  }
                }

                p {
                  font-size: 16px;
                  color: #aeaeae;
                  padding: 2rem 0;
                }
              }

              .hero-sectiom-image {
                margin-bottom: 1rem;
              }

              .cta {
                text-align: center;
                margin-bottom: 2rem;
                @media (min-width: 992px) {
                  text-align: left;
                  display: flex;
                }
                a {
                  position: relative;
                  z-index: 0;
                  overflow: hidden;
                  border-radius: 15px;
                  padding-top: 13px;
                  padding-bottom: 13px;
                  padding-left: 40px;
                  padding-right: 40px;
                  font-size: 18px;
                  background: var(--white);
                  color: var(--black1);
                  display: inline-block;
                  font-weight: 700;
                  line-height: 140%; /* 22.4px */
                  letter-spacing: 0.272px;
                  text-transform: uppercase;
                  &:hover,
                  &:focus {
                    background-color: var(--accent);
                  }
                }

                span {
                  display: block;
                  font-weight: 500;
                  font-size: 1rem;
                  color: #ff9e98;

                  @media (min-width: 992px) {
                    padding: 10px 33px;
                    margin-left: -21px;
                    background: #520500;
                    border-radius: 32px;
                  }
                }
              }
              .hero-section-image {
                margin-bottom: 2rem;
              }
            }
          }
        }

        .card-container {
          margin-top: 2rem;
          position: relative;
          padding: 17px;
          @media (min-width: 992px) {
            text-align: right;
            margin-top: 0;
            width: 50%;
          }
          .mainbg {
            width: 100%;
            position: relative;
            @media (min-width: 992px) {
              display: flex;
              justify-content: right;
            }
            img {
              position: relative;
              display: block;
              background: var(--accent);
              border-radius: 24px;
              @media (min-width: 992px) {
                height: 750px;
              }
            }
          }
        }
        .question-item {
          padding: 0 1rem;
          .congrats {
            max-width: 580px;
            width: 100%;
            background-color: var(--white);
            padding: 1.25rem;

            @media (min-width: 992px) {
              padding: 2rem;
            }

            border-radius: 18px;
            text-align: center;

            h1 {
              font-size: calc(1.375rem + 1.5vw);
              line-height: 1.5;

              @media (min-width: 992px) {
                font-size: 2.5rem;
              }
            }

            h2 {
              color: #0058ff;
              margin-bottom: 0.5rem;
              font-size: 1.4rem;
              line-height: 120%;
            }

            a {
              display: block;
              margin: 1rem 0;
              background: var(--black);
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
              transition: all 0.2s ease-in-out;

              &:hover,
              &:focus {
                background: var(--accent);
              }
            }

            p {
              span {
                color: #0058ff;
                font-weight: bold;
              }
            }

            img {
              margin-top: 1rem;
            }
          }
        }
        .question-wrapper {
          flex: auto;
          width: 100%;
          background-color: var(--black);
          margin: 3rem 1rem;
          padding: 1.5rem;
          border-radius: 10px;

          @media (min-width: 992px) {
            padding: 2.5rem;
            max-width: 600px;
          }

          .headline-wrapper {
            h1 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 10px;
              color: var(--white);
              letter-spacing: -0.3px;
              text-transform: capitalize;
              text-align: center;
            }
          }

          .question-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .question {
              position: relative;
              text-align: center;

              @media (min-width: 992px) {
                padding: 0;
                background-color: transparent;
                box-shadow: none;
                margin: 0;
              }

              border-radius: 18px;
              margin: 1rem auto;
              max-width: 580px;
              width: 100%;

              h2 {
                font-size: 25px;
                font-weight: 700;
                color: var(--white);

                @media (min-width: 1200px) {
                  font-size: 2rem;
                }
              }

              a {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1rem 0;
                background: var(--white);
                color: var(--black);
                font-weight: bold;
                text-decoration: none;
                padding: 0 1rem;
                font-size: 18px;
                border-radius: 8px;
                box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
                height: 50px;
              }
              .option-renderer-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                margin-top: 2rem;
                .options-container {
                  width: 100%;
                  input {
                    display: none;
                  }

                  .option-button {
                    width: 100%;
                    outline: none;
                    border: none;
                    display: flex;
                    align-items: center;
                    padding: 16px 32px;
                    font-size: 18px;
                    border-radius: 8px;
                    cursor: pointer;
                    justify-content: center;
                    background: var(--white);
                    color: var(--black);
                    text-align: center;
                    user-select: none;
                    font-weight: bold;
                    -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                    -ms-border-radius: 8px;
                    -o-border-radius: 8px;
                  }
                }
              }
            }
          }

          h1 {
            color: #fff;
          }
        }
      }
    }

    .question-item {
      padding: 0 1rem;
      .congrats {
        max-width: 580px;
        width: 100%;
        background-color: var(--bg-color);
        padding: 1.25rem;

        @media (min-width: 992px) {
          padding: 2rem;
        }

        border-radius: 18px;
        text-align: center;

        h1 {
          font-size: calc(1.375rem + 1.5vw);
          line-height: 1.5;

          @media (min-width: 992px) {
            font-size: 2.5rem;
          }
        }

        h2 {
          color: #0058ff;
          margin-bottom: 0.5rem;
          font-size: 1.4rem;
          line-height: 120%;
        }

        a {
          display: block;
          margin: 1rem 0;
          background: var(--black);
          color: #fff;
          font-weight: bold;
          text-decoration: none;
          padding: 1rem;
          font-size: 1.5rem;
          border-radius: 8px;
          box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
          transition: all 0.2s ease-in-out;

          &:hover,
          &:focus {
            background: var(--accent);
          }
        }

        p {
          span {
            color: #0058ff;
            font-weight: bold;
          }
        }

        img {
          margin-top: 1rem;
        }
      }
    }
    .question-wrapper {
      flex: auto;
      width: 100%;
      background-color: var(--bg-color);
      margin: 0 1rem;
      padding: 1.5rem;
      border-radius: 10px;

      @media (min-width: 992px) {
        margin: 0;
        padding: 2.5rem;
        max-width: 600px;
      }

      .headline-wrapper {
        h1 {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 10px;
          color: var(--accent);
          letter-spacing: -0.3px;
          text-transform: capitalize;
          text-align: center;
        }
      }

      .question-item {
        .question {
          position: relative;
          text-align: center;

          @media (min-width: 992px) {
            padding: 0;
            background-color: transparent;
            box-shadow: none;
            margin: 0;
          }

          border-radius: 18px;
          margin: 1rem auto;
          max-width: 580px;
          width: 100%;

          h2 {
            font-size: 25px;
            font-weight: 700;

            @media (min-width: 1200px) {
              font-size: 2rem;
            }
          }

          a {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 1rem 0;
            background: var(--black);
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            padding: 0 1rem;
            font-size: 18px;
            border-radius: 8px;
            box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
            height: 50px;
          }
        }
      }

      h1 {
        color: #fff;
      }
    }
  }
  .card-section {
    position: relative;
    z-index: 5;
    backdrop-filter: blur(15px);
    background: white;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 2rem;
    border-radius: 24px;

    .container {
      border-radius: 10px;

      @media (min-width: 992px) {
        padding: 41px 72px;
      }
      .row {
        justify-content: center;

        .custom-container {
          width: 100%;

          .icon-pack {
            margin-top: 1rem;
            @media (min-width: 992px) {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            }
            .headline-back {
              text-align: center;
              flex: 0 0 auto;
              width: 100%;
              @media (min-width: 992px) {
                width: 20%;
              }
              h2 {
                font-size: 1rem;
                font-weight: 600;
                letter-spacing: 2px;
              }
            }

            .logo-icon-pack {
              max-width: 38.75rem;
              margin: 1rem auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              gap: 0.5rem;

              @media (min-width: 992px) {
                width: 80%;
                margin: 0;
                flex: 0 0 auto;
                max-width: 100%;
              }

              img {
                flex: 0 0 auto;
                width: 25%;
                margin-bottom: 0.25rem;

                @media (min-width: 992px) {
                  width: 12%;
                }
              }
            }
          }
        }
      }
    }
  }

  .section1 {
    padding: 3rem 0;
    @media (min-width: 992px) {
      padding: 4rem 0;
    }
    .container {
      .row {
        align-items: center;
        .section-1-content {
          width: 100%;
          text-align: center;
          padding: 64px 24px;
          background: var(--accent);
          border-radius: 24px;
          @media (min-width: 992px) {
            width: 50%;
            padding: 136px 64px;

            text-align: left;
          }
          h1 {
            margin-bottom: 1rem;
            line-height: 140%;
            font-size: 24px;
            letter-spacing: 0.036px;
            @media (min-width: 992px) {
              font-size: 2rem;
              width: 84%;
            }
          }
          p {
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.08px;
            @media (min-width: 992px) {
              width: 87%;
            }
          }
          .cta-holder {
            max-width: 580px;
            margin: 1rem 0;

            a {
              position: relative;
              z-index: 0;
              overflow: hidden;
              border-radius: 10px;
              padding-top: 13px;
              padding-bottom: 13px;
              padding-left: 40px;
              padding-right: 40px;
              font-size: 18px;
              font-weight: 500;
              line-height: 1.5rem;
              letter-spacing: -0.3px;
              background: var(--black);
              color: var(--white);
              display: inline-block;

              &:hover,
              &:focus {
                background: linear-gradient(
                  84.6deg,
                  #0057ff 8.13%,
                  #5181ff 91.36%
                );
              }
            }
          }
        }
        .item-benefits {
          margin: 2rem 0;
          width: 100%;

          @media (min-width: 992px) {
            width: 50%;
            padding: 0 1rem;
            display: flex;
            flex-wrap: wrap;
          }
          ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
              flex: 0 0 auto;
              width: 100%;
              margin-bottom: 1rem;

              @media (min-width: 992px) {
                max-width: 47%;
                margin: 1rem 0.25rem;
                min-height: 257px;
              }
              .benefit {
                height: 100%;
                border-radius: 10px;
                background-color: var(--white);
                position: relative;
                padding: 28px;
                box-shadow: 10px 13px 40px 0px rgba(0, 0, 0, 0.05);

                .benefitImage {
                  flex: 0 0 auto;
                  position: relative;
                  margin-bottom: 1.5rem;
                  display: inline-block;
                  padding: 20px;
                  background: var(--accent);

                  border-radius: 8px;
                  img {
                    max-width: 25px;
                    vertical-align: middle;
                  }
                }
                .benefitHeadline {
                  flex: 0 0 auto;
                  // display: flex;
                  // flex-wrap: wrap;

                  h3 {
                    font-size: 20px;
                    font-weight: 700;
                    display: block;
                    margin-bottom: 0.5rem;
                    color: var(--black);
                    letter-spacing: 0.06px;
                  }
                  p {
                    font-size: 14px;
                    color: var(--black);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .section2 {
    padding: 3rem 0;
    @media (min-width: 992px) {
      padding: 5rem 0;
    }
    .container {
      background: var(--black1);
      padding: 4rem 2rem;
      border-radius: 24px;

      .row {
        .section2-headline-container {
          width: 100%;
          text-align: center;
          @media (min-width: 992px) {
            width: 47%;
            text-align: left;
            margin-left: 2rem;
          }
          h1 {
            margin-bottom: 1rem;
            font-size: 24px;
            font-weight: 700;
            line-height: 140%; /* 33.6px */
            letter-spacing: 0.036px;
            color: var(--white);
            @media (min-width: 992px) {
              font-size: 2.5rem;
              width: 82%;
            }
          }

          p {
            margin-bottom: 2rem;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: 0.08px;
            color: var(--white);
            @media (min-width: 992px) {
              width: 82%;
            }
          }
        }

        .section2-stat-counter {
          width: 100%;
          border-radius: 8px;

          @media (min-width: 992px) {
            display: grid;
            width: 49%;
            flex-wrap: wrap;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
          }

          .statCounter {
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: var(--black2);
            padding: 16px 0;
            width: 100%;
            border-radius: 8px;
            @media (min-width: 992px) {
              min-width: 220px;
              /* align-items: center; */
              /* justify-content: space-evenly; */
            }

            .statCounterStats {
              // display: flex;
              width: 227px;
              gap: 1rem;
              margin-left: 1rem;

              h3 {
                color: var(--yellow);
              }
              p {
                font-size: 15px;
                color: var(--white);
              }
            }
          }
        }
      }
    }
  }
  .testimonial {
    padding: 3rem 0;
    text-align: left;
    @media (min-width: 992px) {
      padding: 4rem 0;
    }

    img {
      max-width: 100%;

      @media (min-width: 992px) {
        max-width: 580px;
      }
    }
    .testimonial-wrapper {
      background: var(--accent);
      border-radius: 24px;
      padding: 64px 56px;
      text-align: center;

      @media (min-width: 992px) {
        padding: 39px 106px;
        text-align: left;
      }

      .testimonial-headline {
        flex: 0 0 auto;
        width: 100%;
        h1 {
          margin-bottom: 1rem;
          line-height: 120%;
          font-size: 24px;
          font-weight: 700;
          line-height: 140%; /* 33.6px */
          letter-spacing: 0.036px;
          color: var(--black1);
          @media (min-width: 1200px) {
            font-size: 2.5rem;
          }
        }
      }

      .testimonial-content-holder {
        flex: 0 0 auto;
        width: 100%;
        @media (min-width: 992px) {
          display: flex;
          justify-content: space-between;
        }

        h3 {
          font-weight: 500;
          color: var(--black1);
        }

        p {
          margin-bottom: 1rem;
          color: var(--black);
          font-size: 14px;

          @media (min-width: 992px) {
            width: 80%;
            font-size: 18px;
          }
        }
      }
    }

    .testimonial-list {
      display: flex;
      margin-top: 2rem;
      flex-wrap: wrap;

      .testimonial-item {
        margin-bottom: 1rem;
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 1.5rem;

        @media (min-width: 992px) {
          width: 33%;
          padding: 0 1rem;
        }

        .testimonial-inner {
          padding: 24px;
          text-align: left;
          border-radius: 10px;
          background-color: var(--white);

          .testimonial-bottom {
            margin-bottom: 16px;
          }

          .testimonial-top {
            display: flex;
            align-items: center;
            &:nth-of-type(1) {
              margin-bottom: 1rem;
            }
            .star-rating {
              display: flex;
              img {
                width: 130px;
              }
            }

            .userimg {
              max-width: 38px;
              border-radius: 100%;
            }

            h3 {
              font-size: 18px;
              font-weight: 500;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .section3 {
    padding: 3rem 0;
    align-items: center;
    .container {
      .row {
        display: block;
        align-items: center;
        justify-content: center;
        @media (min-width: 992px) {
          display: flex;
          justify-content: space-between;
        }

        .faq {
          flex: 0 0 auto;
          width: 100%;
          text-align: center;
          background: black;
          padding: 40px 24px;
          border-radius: 24px;

          @media (min-width: 992px) {
            width: 44%;
            text-align: left;
            padding: 139px 75px;
          }

          h2 {
            font-size: 21px;
            font-weight: 800;
            line-height: 34px;
            letter-spacing: 0.003em;
            margin-bottom: 8px;
            color: var(--white);

            @media (min-width: 992px) {
              font-size: 2.5rem;
              line-height: 50px;
            }
          }
          p {
            color: var(--white);
            @media (min-width: 992px) {
              width: 70%;
            }
          }
        }

        .accordion {
          margin-top: 50px;
          cursor: pointer;
          @media (min-width: 992px) {
            width: 50%;
          }
          .accordion-list {
            margin-bottom: 18px;
            background-color: var(--white);
            padding: 16px 20px;
            box-sizing: border-box;
            border-radius: 10px;

            .accordion-title {
              display: flex;
              justify-content: space-between;
              .question {
                font-size: 1rem;
                font-weight: 600;
              }
            }
          }

          .accordion-list.active {
            background-color: var(--accent);
            .question {
              color: var(--black1);
              @media (min-width: 992px) {
                font-size: 21px;
              }
            }
          }
          .icon {
            margin-right: 14px;
            padding: 2px 4px;
            position: relative;
            margin-bottom: 5px 5px;
            display: inline-block;

            border-radius: 8px;
            cursor: pointer;
          }

          .answer {
            margin-top: 10px;
            color: var(--black);
            font-size: 12px;

            @media (min-width: 992px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 4rem 0 6rem 0;
    background-color: var(--white);
    .top-footer {
      p {
        font-size: 0.875rem;
        color: var(--black1);
      }
    }

    .bottom-footer {
      .footer-navigation {
        flex: 0 0 auto;
        width: 100%;
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @media (min-width: 992px) {
          justify-content: space-between;
        }

        .footer-copyright {
          flex: 0 0 auto;
          width: 100%;
          p {
            color: var(--black1);
          }
          @media (min-width: 992px) {
            width: auto;
          }
        }

        .footer-navigation-wrapper {
          flex: 0 0 auto;
          width: 100%;

          @media (min-width: 992px) {
            width: auto;
          }

          ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
              color: var(--black1);

              &:nth-child(1) {
                &::after {
                  content: "|";
                  margin: 0 1rem;
                  color: var(--black1);
                }
              }

              a {
                text-decoration: none;
                color: var(--black1);
              }
            }
          }
        }
      }
    }
  }

  .policy-wrapper {
    .policy-title {
      padding: 2rem 0;

      h1 {
        font-size: calc(1.375rem + 1.5vw);

        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }

      flex: 0 0 auto;
      width: 100%;
      border-bottom: 1px solid gray;
      margin-bottom: 2rem;
    }

    .policy-paragraph {
      padding-bottom: 4rem;

      p {
        margin-bottom: 0.5rem;
      }

      h2 {
        font-size: calc(1.325rem + 0.9vw);
        margin: 1rem 0;

        @media (min-width: 1200px) {
          font-size: 2rem;
        }
      }

      h3 {
        font-size: calc(1.3rem + 0.6vw);
        margin-bottom: 1rem;

        @media (min-width: 1200px) {
          font-size: 1.75rem;
        }
      }

      h3 {
        font-size: calc(1.275rem + 0.3vw);

        @media (min-width: 1200px) {
          font-size: 1.5rem;
        }
      }

      ul {
        list-style-type: disc;
        margin-bottom: 0.5rem;
      }
    }
  }
}
